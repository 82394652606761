exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-journey-js": () => import("./../../../src/pages/journey.js" /* webpackChunkName: "component---src-pages-journey-js" */),
  "component---src-pages-non-surgical-treatments-js": () => import("./../../../src/pages/non-surgical-treatments.js" /* webpackChunkName: "component---src-pages-non-surgical-treatments-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-surgery-js": () => import("./../../../src/pages/surgery.js" /* webpackChunkName: "component---src-pages-surgery-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-templates-archive-post-js": () => import("./../../../src/templates/archivePost.js" /* webpackChunkName: "component---src-templates-archive-post-js" */),
  "component---src-templates-single-page-js": () => import("./../../../src/templates/singlePage.js" /* webpackChunkName: "component---src-templates-single-page-js" */),
  "component---src-templates-single-post-js": () => import("./../../../src/templates/singlePost.js" /* webpackChunkName: "component---src-templates-single-post-js" */),
  "component---src-templates-upper-lower-treatment-js": () => import("./../../../src/templates/upperLowerTreatment.js" /* webpackChunkName: "component---src-templates-upper-lower-treatment-js" */)
}

